import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./no-evaluations.styles.module.scss";
import imgNoActiveEvaluations from "./no-evaluations.svg";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const NoEvaluations = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent color="coal" boldness="semi" styling="p4" rootClassName={styles.title}>
              {t("No active evaluations")}
            </TypographyComponent>
            <TypographyComponent color="iron" styling="p4">
              {t("Your most recent evaluation activity will appear here")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent color="coal" boldness="semi" type="p4" rootClassName={styles.title}>
              {t("No active evaluations")}
            </TypographyComponent>
            <TypographyComponent color="iron" type="p4">
              {t("Your most recent evaluation activity will appear here")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <img alt={t("No active evaluations")} className={styles.imgEmpty} src={imgNoActiveEvaluations} />
    </div>
  );
};

export default NoEvaluations;
