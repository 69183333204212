const EvaluationsIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 12C11.25 11.7239 11.4739 11.5 11.75 11.5H18.75C19.0261 11.5 19.25 11.7239 19.25 12C19.25 12.2761 19.0261 12.5 18.75 12.5H11.75C11.4739 12.5 11.25 12.2761 11.25 12Z"
        fill="#CC4308"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5556 9H21.4444C22.3 9 23 9.675 23 10.5V13.5C23 14.325 22.3 15 21.4444 15H10.5556C9.7 15 9 14.325 9 13.5V10.5C9 9.675 9.7 9 10.5556 9ZM10.5556 10H21.4444C21.7823 10 22 10.2613 22 10.5V13.5C22 13.7387 21.7823 14 21.4444 14H10.5556C10.2177 14 10 13.7387 10 13.5V10.5C10 10.2613 10.2177 10 10.5556 10Z"
        fill="#CC4308"
      />
      <path
        d="M11.75 19.5C11.4739 19.5 11.25 19.7239 11.25 20C11.25 20.2761 11.4739 20.5 11.75 20.5H15.75C16.0261 20.5 16.25 20.2761 16.25 20C16.25 19.7239 16.0261 19.5 15.75 19.5H11.75Z"
        fill="#CC4308"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4444 17H10.5556C9.7 17 9 17.675 9 18.5V21.5C9 22.325 9.7 23 10.5556 23H21.4444C22.3 23 23 22.325 23 21.5V18.5C23 17.675 22.3 17 21.4444 17ZM10.5556 18C10.2177 18 10 18.2613 10 18.5V21.5C10 21.7387 10.2177 22 10.5556 22H21.4444C21.7823 22 22 21.7387 22 21.5V18.5C22 18.2613 21.7823 18 21.4444 18H10.5556Z"
        fill="#CC4308"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        fill="#CC4308"
      />
    </svg>
  );
};

export default EvaluationsIcon;
