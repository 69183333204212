const ResearchHistoryIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#CC4308"
      />
      <g clipPath="url(#clip0_5307_467)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 11H12V14H9L9 11ZM8 11C8 10.4477 8.44772 10 9 10H12C12.5523 10 13 10.4477 13 11V14C13 14.5523 12.5523 15 12 15H9C8.44772 15 8 14.5523 8 14V11ZM9 18H12V21H9L9 18ZM8 18C8 17.4477 8.44772 17 9 17H12C12.5523 17 13 17.4477 13 18V21C13 21.5523 12.5523 22 12 22H9C8.44772 22 8 21.5523 8 21V18ZM15 12.5C15 12.2239 15.2239 12 15.5 12H23.5C23.7761 12 24 12.2239 24 12.5C24 12.7761 23.7761 13 23.5 13H15.5C15.2239 13 15 12.7761 15 12.5ZM15.5 19C15.2239 19 15 19.2239 15 19.5C15 19.7761 15.2239 20 15.5 20H23.5C23.7761 20 24 19.7761 24 19.5C24 19.2239 23.7761 19 23.5 19H15.5Z"
          fill="#CC4308"
        />
      </g>
      <defs>
        <clipPath id="clip0_5307_467">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResearchHistoryIcon;
