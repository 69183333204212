import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./ftux-banner.styles.module.scss";
import ftuxHero from "./ftux-hero.svg";
import ftuxBackground from "./ftux-background.svg";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const FTUXBanner = () => {
  const { t } = useTranslation();

  return (
    <section aria-label={t("First time user experience")} className={styles.root}>
      <div className={styles.ftuxBackground} style={{ background: `url(${ftuxBackground}) no-repeat -240px 0px` }} />
      <div className={styles.body}>
        <div className={styles.main}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                color={"gartnerBlue"}
                styling={"h3"}
                boldness={"medium"}
                rootClassName={styles.title}
              >
                {t("Discover the right market to kickstart your evaluation")}
              </TypographyComponent>
              <TypographyComponent color={"coal"} styling={"p3"} boldness={"medium"} rootClassName={styles.description}>
                {t(
                  "Using Gartner's industry leading insights, find the market that fits your team's technology needs."
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color={"gartnerBlue"} type={"h3"} rootClassName={styles.title}>
                {t("Discover the right market to kickstart your evaluation")}
              </TypographyComponent>
              <TypographyComponent color={"coal"} type={"p3"} rootClassName={styles.description}>
                {t(
                  "Using Gartner's industry leading insights, find the market that fits your team's technology needs."
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <Button
            onClick={() => {
              navigateToUrl("/s/markets");
            }}
            rootClassName={"btn-primary"}
          >
            {t("Browse markets")}
          </Button>
        </div>
        <div className={styles.heroContainer}>
          <img
            alt={t("Discover the right market to kickstart your evaluation")}
            className={styles.hero}
            src={ftuxHero}
          />
        </div>
      </div>
    </section>
  );
};

export default FTUXBanner;
