import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./no-tasks.styles.module.scss";
import imgTasksEmpty from "./no-tasks.svg";

const NoTasks = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.text}>
        <TypographyComponent color="coal" boldness="semi" type="p4" rootClassName={styles.title}>
          {t("No tasks available yet")}
        </TypographyComponent>
        <TypographyComponent color="iron" type="p4">
          {t("All checklist tasks that have been assigned to you will appear here")}
        </TypographyComponent>
      </div>
      <img alt={t("No tasks available yet")} className={styles.imgEmpty} src={imgTasksEmpty} />
    </>
  );
};

export default NoTasks;
