export enum ClientEvent {
  HOME_PAGE_VIEWED = "HOME_PAGE_VIEWED",
  HOME_ACTIVE_EVALUATIONS_CLICKED = "HOME_ACTIVE_EVALUATIONS_CLICKED",
  HOME_BROWSE_MARKETS_CLICKED = "HOME_BROWSE_MARKETS_CLICKED",
  HOME_RECENT_MARKET_CLICKED = "HOME_RECENT_MARKET_CLICKED",
  HOME_RECENT_VENDOR_CLICKED = "HOME_RECENT_VENDOR_CLICKED",
}

export enum InitiativeStateChangeAction {
  ARCHIVE = "ARCHIVE",
  DELETE = "DELETE",
}
