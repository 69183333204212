const LastViewedMarketsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3333 8.33333H17.6667C18.4 8.33333 19 8.93333 19 9.66667V17.6667C19 18.4 18.4 19 17.6667 19H6.33333C5.6 19 5 18.4 5 17.6667V9.66667C5 8.93333 5.6 8.33333 6.33333 8.33333H8.66667C8.66667 6.46667 10.1333 5 12 5C13.8667 5 15.3333 6.46667 15.3333 8.33333ZM14 8.33333C14 7.2 13.1333 6.33333 12 6.33333C10.8667 6.33333 10 7.2 10 8.33333H14Z"
        fill="#8D8A89"
      />
    </svg>
  );
};

export default LastViewedMarketsIcon;
