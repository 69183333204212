const TasksIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" stroke="#CC4308" />
      <g clipPath="url(#clip0_5307_513)">
        <path
          d="M16 9C14.1435 9 12.363 9.7375 11.0503 11.0503C9.7375 12.363 9 14.1435 9 16C9 17.8565 9.7375 19.637 11.0503 20.9497C12.363 22.2625 14.1435 23 16 23"
          stroke="#CC4308"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13 14.8L15.8571 18L23 10" stroke="#CC4308" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M19.4327 19.2383L19.6314 18.8044C19.7756 18.4898 20.2229 18.4904 20.3662 18.8055L20.5623 19.2371C20.6027 19.326 20.674 19.3973 20.7629 19.4377L21.1945 19.6338C21.5096 19.7771 21.5102 20.2244 21.1956 20.3686L20.7617 20.5673C20.6735 20.6078 20.6028 20.6786 20.5626 20.7669L20.3659 21.1979C20.2225 21.5123 19.776 21.513 19.6317 21.199L19.4324 20.7657C19.3921 20.6781 19.3219 20.6079 19.2343 20.5676L18.801 20.3683C18.487 20.224 18.4877 19.7775 18.8021 19.6341L19.2331 19.4374C19.3214 19.3972 19.3922 19.3265 19.4327 19.2383Z"
          fill="#CC4308"
        />
      </g>
      <defs>
        <clipPath id="clip0_5307_513">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TasksIcon;
