import { useTranslation } from "react-i18next";
import { useUserState } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./header.styles.module.scss";
import React from "react";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const Header = () => {
  const { t } = useTranslation();
  const { firstName } = useUserState();
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent color="gartnerBlue" boldness="medium" type="p3">
              {t("Welcome")} {firstName}!
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent color="gartnerBlue" boldness="semi" type="p3">
              {t("Welcome")} {firstName}!
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </section>
  );
};

export default Header;
