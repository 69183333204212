import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import NoResearchHistorySvg from "./no-research-history.svg";
import styles from "./no-research-history.styles.module.scss";

const NoResearchHistory = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <TypographyComponent color="coal" boldness="semi" type="p4" rootClassName={styles.title}>
        {t("You have not viewed a market or vendor profile yet")}
      </TypographyComponent>
      <img alt={t("You have not viewed a market or vendor profile yet")} src={NoResearchHistorySvg} width={129} />
    </Fragment>
  );
};

export default NoResearchHistory;
