import { createContext, ReactNode, useState } from "react";
import { HomeAppContextValue, Initiative, ChecklistTasks, RecentProductView, RecentTemplateView } from "./app.types";

const HomeAppContext = createContext<HomeAppContextValue>({} as HomeAppContextValue);
const HomeAppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [checklistTasks, setChecklistTasks] = useState<Array<ChecklistTasks>>([]);
  const [error, setError] = useState<boolean>(false);
  const [initiatives, setInitiatives] = useState<Array<Initiative>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [recentProductViews, setRecentProductViews] = useState<RecentProductView[]>([]);
  const [recentTemplateViews, setRecentTemplateViews] = useState<RecentTemplateView[]>([]);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [saveDataErrorStatus, setSaveDataErrorStatus] = useState<boolean>(false);

  const contextValue = {
    checklistTasks,
    error,
    initiatives,
    isLoading,
    recentProductViews,
    recentTemplateViews,
    showSnackBar,
    snackbarMessage,
    saveDataErrorStatus,
    setChecklistTasks,
    setError,
    setInitiatives,
    setLoading,
    setRecentProductViews,
    setRecentTemplateViews,
    setShowSnackBar,
    setSnackbarMessage,
    setSaveDataErrorStatus,
  };

  return <HomeAppContext.Provider value={contextValue}>{props.children}</HomeAppContext.Provider>;
};

export { HomeAppContext, HomeAppContextProvider };
